




































































import { authModule } from '@t/session';
import { vxm } from '@/store';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IProject } from '@/entity/project/project';
import { NU } from '@t/type';

@Component({})
export default class BaseHeader extends Vue {
    @Prop({ required: false, default: 'ED4305' }) protected borderColor!: string;

    public selectedLanguage: string = '';
    private filterData: string = '';

    get projects(): IProject[] {
        let list = vxm.project.dropdownProjectListOptions;
        if (this.filterData && this.filterData !== '') {
            list = list.filter((item) => {
                return (
                    item.designation.toUpperCase().indexOf(this.filterData.toUpperCase()) > -1 ||
                    item.trigram.toUpperCase().indexOf(this.filterData.toUpperCase()) > -1
                );
            });
        }
        return list;
    }

    private currentRoute = this.$router.currentRoute.name;

    private loading: boolean = false;

    private lastLangStorageName: string = 'last-language';

    async mounted(): Promise<void> {
        this.loading = true;
        const lastLang = localStorage.getItem(this.lastLangStorageName);
        if (lastLang && lastLang.length > 1) {
            this.selectLanguage(lastLang);
        } else {
            this.selectLanguage('en');
        }

        await vxm.project.fetchDropDownProjectOptions();
        /* .then((x) => {
            if (this.projects.length > 0) {
                const lsVar = localStorage.getItem('selected-project');
                if (lsVar) {
                    this.updateProject(this.projects.find(x => x.id === Number(lsVar)) as IProject);
                } else {
                    this.updateProject(this.projects[0]);
                }
            }
        }); */

        this.loading = false;
        this.routeIsValidForProjectSelector;
    }

    @Watch('projects')
    private firstLoadSelect(newVal: IProject[]) {
        if (newVal.length > 0) {
            const lsVar = localStorage.getItem('selected-project');
            if (lsVar) {
                this.updateProject(newVal.find((x) => x.id === Number(lsVar)) as IProject);
            } else {
                this.updateProject(newVal[0]);
            }
        }
    }

    updateProject(project: IProject): void {
        if (project) {
            vxm.project.updateSelectedProject(project.id + '');
            localStorage.setItem('selected-project', String(project.id));
        }
    }

    get isSidebarCollapsed(): boolean {
        return vxm.app.sidebarCollapsed;
    }

    get currentUserId(): string | undefined {
        return authModule.getAccount()?.localAccountId;
    }

    get routeIsValidForProjectSelector(): boolean {
        return (
            ['home', 'projectPlanification', 'projectManagements', 'subcontracting', 'provisionalfee'].findIndex(
                (x) => x === this.currentRoute
            ) !== -1
        );
    }

    get currentProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get titleMain(): string {
        return vxm.app.titleValueMain;
    }

    get titleExt(): string {
        return vxm.app.titleValueExt;
    }

    public selectLanguage(newLanguage: string): void {
        this.selectedLanguage = newLanguage;
        localStorage.setItem(this.lastLangStorageName, this.selectedLanguage);
    }

    public setBackground(): boolean {
        return this.$route.name !== 'login' && this.$route.name !== 'externalValidation';
    }

    @Watch('$route')
    private updateRoute(newVal: any) {
        this.currentRoute = newVal.name;
    }
}
