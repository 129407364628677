
















































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import BaseSidebarItem from '../base-sidebar-item.vue';
import BaseLoader from '@/components/shared/base-loader.vue';
import { authModule } from '@t/session';
import { RouteConfig } from 'node_modules/vue-router/types';
import { appTokenMgr } from '@t/employee-app-role';

@Component({
    components: {
        BaseSidebarItem,
        BaseLoader
    }
})
export default class SidebarPanel extends Vue {
    @Prop({ required: false, default: () => false }) collapsed!: boolean;

    private contributorsCollapse: boolean = true;
    private projectsCollapse: boolean = true;
    private usersCollapse: boolean = true;
    // private reportsCollapse: boolean = true;
    private configsCollapse: boolean = true;

    private forceContributorsActive: boolean = false;
    private forceProjectsActive: boolean = false;
    private forceUsersActive: boolean = false;
    private forceReportsActive: boolean = false;
    private forceConfigsActive: boolean = false;

    get userId(): string {
        return authModule.getAccount()?.localAccountId as string;
    }

    get canAccessPlanification(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc));
    }

    get canAccessSubcontracting(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc));
    }

    get canAccessClients(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc));
    }

    get canAccessSuppliers(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc));
    }

    get canAccessEmployeeList(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isAssociate(acc));
    }

    get canAccessConfigurations(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc));
    }

    get canAccessProjectManagement(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc));
    }

    get canAccessReporting(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc));
    }

    @Watch('$route')
    private routeChange(to: RouteConfig, from: RouteConfig) {
        this.contributorsCollapse = true;
        this.projectsCollapse = true;
        this.usersCollapse = true;
        this.configsCollapse = true;
        this.setForceActive(to.path);
    }

    private setForceActive(path: string) {
        this.forceContributorsActive = false;
        this.forceProjectsActive = false;
        this.forceUsersActive = false;
        this.forceReportsActive = false;
        this.forceConfigsActive = false;
        if (path.match('/clients') || path.match('/suppliers')) {
            this.forceContributorsActive = true;
        } else if (path.match('/users/')) {
            this.forceUsersActive = true;
        } else if (
            path.match('/projects') ||
            path.match('/planification') ||
            path.match('/subcontracting') ||
            path.match('/provisionalfee') ||
            path.match('/managements')
        ) {
            this.forceProjectsActive = true;
        } else if (path.match('/referential/')) {
            this.forceConfigsActive = true;
        } else if (path.match('/reporting')) {
            this.forceReportsActive = true;
        }
    }

    @Watch('collapsed')
    private collapsedChange(newVal: boolean, _oldVal: boolean) {
        if (newVal) {
            this.projectsCollapse = true;
            this.contributorsCollapse = true;
            this.usersCollapse = true;
            // this.reportsCollapse = true;
            this.configsCollapse = true;
            this.setForceActive(this.$route.path);
        }
    }

    private openContributors() {
        if (this.collapsed && this.contributorsCollapse === false) {
            return;
        }
        this.contributorsCollapse = !this.contributorsCollapse;
        if (this.contributorsCollapse === false) {
            this.projectsCollapse = true;
            this.usersCollapse = true;
            this.configsCollapse = true;
        }
    }

    private openConfigs() {
        if (this.collapsed && this.configsCollapse === false) {
            return;
        }
        this.configsCollapse = !this.configsCollapse;
        if (this.configsCollapse === false) {
            this.projectsCollapse = true;
            this.usersCollapse = true;
            this.contributorsCollapse = true;
        }
    }

    private openProjects() {
        if (this.collapsed && this.projectsCollapse === false) {
            return;
        }
        this.projectsCollapse = !this.projectsCollapse;
        if (this.projectsCollapse === false) {
            this.contributorsCollapse = true;
            this.usersCollapse = true;
            this.configsCollapse = true;
        }
    }

    private openUsers() {
        if (this.collapsed && this.usersCollapse === false) {
            return;
        }
        this.usersCollapse = !this.usersCollapse;
        if (this.usersCollapse === false) {
            this.contributorsCollapse = true;
            this.projectsCollapse = true;
            this.configsCollapse = true;
        }
    }

    private mounted(): void {
        this.setForceActive(this.$route.path);
    }
}
