



























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BaseSidebarItem extends Vue {
    @Prop({ required: false, default: () => '' }) protected text!: string;
    @Prop({ required: false, default: () => false }) protected collapsed!: boolean;
    @Prop({ required: false, default: () => '' }) protected icon!: string;
    @Prop({ required: false, default: () => '' }) protected iconActive!: string;
    @Prop({ required: false, default: () => '' }) protected localIcon!: string;
    @Prop({ required: false, default: () => '' }) protected localIconActive!: string;
    @Prop({ required: false, default: () => '' }) protected to!: string | { name: string; params: any };
    @Prop({ required: false }) protected forceActive: boolean | undefined;
    @Prop({ required: false }) protected noIndicator: boolean | undefined;
    @Prop({ required: false, default: () => false }) protected noActive!: boolean;

    get activeIcon() {
        if (this.to || this.forceActive) {
            return this.iconActive ? this.iconActive : this.icon;
        }
        return this.icon;
    }

    get activeLocalIcon() {
        if (this.to || this.forceActive) {
            return this.localIconActive ? this.localIconActive : this.localIcon;
        }
        return this.localIcon;
    }

    get activeState() {
        if (this.noActive) {
            return false;
        }
        if (this.to) {
            return true;
        }
        return false;
    }
}
