












import { Component, Vue } from 'vue-property-decorator';
import BaseSidebar from '@c/layout/base-sidebar.vue';
import BaseHeader from '@c/layout/base-header.vue';

@Component({
    components: {
        BaseSidebar,
        BaseHeader
    }
})
export default class App extends Vue {
    public showMenu(): boolean {
        return this.$route.name !== 'login' && this.$route.name !== 'externalValidation';
    }
}
